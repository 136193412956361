import React from 'react'
import styled from 'styled-components'

import Layout from 'components/Layout'

import Colors from 'utils/Colors'
import * as Mixins from 'utils/Mixins'
import * as t from 'utils/Typography'

import { myContext } from '../themeProvider'
import { Background } from '../utils/styles'

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin};
  padding: 20em 0 20em 0;
  color: ${(props) => (props.theme === 'white' ? Colors.darkM_Background : Colors.white)};
`

class NotFoundPage extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  renderPage = (context) => {
    const { theme, changeTheme } = context

    return (
      <Background theme={theme}>
        <Layout theme={theme} onChangeTheme={changeTheme}>
          <AboveFold theme={theme}>
            <t.H1 green align="center">
              404
            </t.H1>
            <t.H3 align="center" max45>
              Not found
            </t.H3>
          </AboveFold>
        </Layout>
      </Background>
    )
  }

  render() {
    return <myContext.Consumer>{(context) => this.renderPage(context)}</myContext.Consumer>
  }
}

export default NotFoundPage
